import { HttpClient } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, map, of, switchMap, tap, throwError, zip } from 'rxjs';
import { ENVIRONMENT_TOKEN } from 'src/environments/environment-token';
import {
  AccountService,
  AdminService,
  AuthAcc,
  AuthService,
  Me,
  Organization,
  OrganizationService,
} from '../core/openapi';
import { LOCALSTORAGE } from '../core/services/localstorage.token';
import OrganisationState from '../core/states/organisation.state';
import { AuthParams, AuthService as FEAuthService } from './auth.service';

type AuthProvider = {
  id: string;
  name: string;
};
@Injectable({
  providedIn: 'root',
})
export class AuthState {
  #adminService = inject(AdminService);
  #accountService = inject(AccountService);
  #feAuthService = inject(FEAuthService);
  #authService = inject(AuthService);
  #organisationState = inject(OrganisationState);
  #orgService = inject(OrganizationService);
  #http = inject(HttpClient);
  #env = inject(ENVIRONMENT_TOKEN);
  #router = inject(Router);
  #ls = inject(LOCALSTORAGE);
  #activeUser = signal<Me | null>(null);
  #token = signal<string | null>(null);
  #originalToken = signal<string | null>(null);
  #impersonatingAccountId = signal<string | null>(null);
  #isSysAdmin = signal<boolean | null>(null);
  #expiresAt = signal<number | null>(this.#ls.getItemParsed<number>('expiresAt') ?? null);
  #authProviders = signal<AuthProvider[]>([]);

  $impersonatingAccountId = this.#impersonatingAccountId.asReadonly();
  $isLoggedIn = computed(() => !!this.#token() && !!this.#activeUser());
  $isSysAdmin = computed(() => this.#isSysAdmin() ?? false);
  $token = this.#token.asReadonly();
  $expiresAt = this.#expiresAt.asReadonly();
  $activeUser = this.#activeUser.asReadonly();
  authProviders = computed(() => {
    this.$activeUser();
    return this.#authProviders();
  });
  $userName = computed(() => {
    const user = this.$activeUser();

    if (user && user.name && user.lastName) {
      return `${user.name} ${user.lastName}`;
    } else if (user && user.name) {
      return user.name;
    } else if (user && user.lastName) {
      return user.lastName;
    } else if (user && user.email) {
      return user.email;
    }

    return 'Account';
  });

  loadAuthProviders() {
    this.#orgService.getOrganizationSsoOverview().subscribe((t) => this.#authProviders.set(t.ssOs));
  }
  addAuthAcc(providerId: string) {
    return this.#authService
      .postAddAuthProviderByProviderTicket({
        provider: providerId,
      })
      .pipe(
        tap((ticket) => {
          const url = `${this.#env.baseUrl}/add-auth-provider/${providerId}/ticket/${ticket.id}?redirecturl=${window.location.protocol}//${window.location.host}/app/settings/account/add-auth-provider-success`; // request url from backend
          const authPopup = window.open(url, '', 'popup=true'); // open popup using that url

          if (authPopup == null)
            window.location.href = `${this.#env.baseUrl}/add-auth-provider/${providerId}/ticket/${ticket.id}?redirecturl=${window.location.href}`; // request url from backend

          const intervalId = setInterval(() => {
            if (authPopup?.closed) {
              clearInterval(intervalId);
              this.fetchUser();
            }
          }, 1000);
        })
      );
  }

  removeAuthAcc(objectId: string, provider: string) {
    const uriEncodedObjectId = window.encodeURIComponent(objectId);
    return this.#authService.deleteAuthByProviderAccountsByObjectId({ objectId: uriEncodedObjectId, provider }).pipe(
      tap(() => {
        this.#activeUser.update((user) => {
          if (user === null) return user;
          const authAccs: AuthAcc[] = user.authAccs
            .map((provider) => ({ ...provider, objectIds: provider.objectIds.filter((t) => t != objectId) }))
            .filter((t) => t.objectIds.length > 0);
          return { ...user, authAccs: authAccs };
        });
      })
    );
  }

  refreshBaseData() {
    return zip([
      this.fetchUser({
        refreshContent: true,
        returnSubscription: true,
      }) as Observable<Me>,
      this.#organisationState.getOrganisation({
        refreshContent: true,
        returnSubscription: true,
      }) as Observable<Organization[]>,
    ]);
  }

  revalidateAuthState(): Observable<boolean> {
    const activeUser = this.#ls.getItemParsed<Me>('activeUser');

    if (activeUser) {
      this.updateActiveUser(activeUser);
    }

    if (this.$token() && activeUser) {
      return of(this.$isLoggedIn());
    }

    if (!this.$token()) {
      return this.refreshIsLoggedIn();
    }

    return this.refreshBaseData().pipe(map(() => this.$isLoggedIn()));
  }

  refreshIsLoggedIn() {
    return this.refreshToken().pipe(map(() => this.$isLoggedIn()));
  }

  fetchUser(options?: { returnSubscription?: boolean; refreshContent?: boolean }) {
    const returnSubscription = options?.returnSubscription ?? false;
    const refreshContent = options?.refreshContent ?? false;

    if (returnSubscription && this.$activeUser() && !refreshContent) {
      return of(this.$activeUser());
    }

    const sub = this.#accountService.getMe().pipe(tap((res) => this.patchActiveUser(res)));

    if (returnSubscription) {
      return sub;
    }

    sub.subscribe();
  }

  loginPlatform(params: AuthParams) {
    return this.#feAuthService.loginPlatform(params).pipe(
      catchError((err) => throwError(() => err.message)),
      tap((res) => this.actionsOnLogin(res as any))
    );
  }

  refreshToken() {
    return this.#feAuthService.refreshToken().pipe(
      catchError((err) => {
        this.logout();

        return throwError(() => err.message);
      }),
      tap((res) => {
        this.updateExpiresAt();
        this.updateToken(res.accessToken as string);
      }),
      switchMap(() => {
        return this.refreshBaseData();
      })
    );
  }

  logout() {
    this.#feAuthService.logout().subscribe({
      complete: () => {
        this.#activeUser.set(null);
        this.#ls.clear();

        location.pathname = '/auth';
      },
      error: (err) => {
        console.error(err);

        location.pathname = '/auth';
      },
    });

    return;
  }

  actionsOnLogin(res?: { accessToken: string }, refreshContent = false) {
    if (typeof res?.accessToken !== 'string') {
      return new Error('Token wasnt a string');
    }

    this.updateExpiresAt();
    this.updateToken(res?.accessToken);

    if (window.opener) {
      window.close();
    }

    zip([
      this.#organisationState.getOrganisation({
        returnSubscription: true,
        refreshContent,
      }) as Observable<Organization[]>,
      this.fetchUser({
        returnSubscription: true,
        refreshContent,
      }) as Observable<Me>,
    ]).subscribe(([org, user]) => {
      this.#router.navigate(['/app']);
    });
  }

  patchActiveUser(partialUser: Partial<Me>) {
    const activeUser = this.#activeUser();

    if (activeUser !== null) {
      return this.updateActiveUser({
        ...activeUser,
        ...partialUser,
      });
    }
    if (partialUser.accountId === undefined) return;

    this.updateActiveUser({
      accountId: partialUser.accountId,
      contractApprovals: partialUser.contractApprovals ?? [],
      created: partialUser.created ?? '',
      lastName: partialUser.lastName ?? null,
      marketingConsent: partialUser.marketingConsent ?? null,
      name: partialUser.name ?? null,
      preferences: partialUser.preferences ?? {},
      currentOrganizationId: partialUser.currentOrganizationId ?? '',
      featureFlags: partialUser.featureFlags ?? [],
      email: partialUser.email ?? null,
      emailPreferences: partialUser.emailPreferences ?? {
        subscriptionRelatedEmails: false,
        onboardingSuggestionEmails: false,
        productUpdateEmails: false,
        productUsageRecommendationEmails: false,
      },
      imageUrl: partialUser.imageUrl ?? null,
      primaryOrganizationId: partialUser.primaryOrganizationId ?? null,
      authAccs: partialUser.authAccs ?? [],
    });
  }

  setActiveOrganization(organization: Organization) {
    const impersonationId = this.$impersonatingAccountId();
    const organizationId = organization.id;
    if (organizationId === null || organizationId === undefined) {
      return throwError(() => 'Organization id is missing');
    }

    if (impersonationId !== null) {
      return this.#adminService
        .postAccountsByAccountIdImpersonate({
          accountId: impersonationId,
          organizationId: organizationId,
        })
        .pipe(
          tap((res) => {
            if (res.token) {
              this.updateToken(res.token);
            }
          }),
          switchMap(() => this.refreshBaseData())
        );
    } else {
      return this.#http
        .put<{ accessToken: string }>(
          `${this.#env.baseUrl}/organization`,
          {
            organizationId: organizationId,
          },
          {
            withCredentials: true,
          }
        )
        .pipe(
          tap((res) => {
            this.updateToken(res.accessToken);
          }),
          switchMap(() => this.refreshBaseData())
        );
    }
  }

  impersonateUser(accountId: string, organizationId?: string) {
    return this.#adminService.postAccountsByAccountIdImpersonate({ accountId, organizationId }).pipe(
      tap((res) => {
        if (res.token) {
          // Save the token for supporting un-impersonate
          if (this.#originalToken() === null) this.#originalToken.set(this.$token());

          this.#impersonatingAccountId.set(accountId);
          this.actionsOnLogin({ accessToken: res.token }, true);
        }
      })
    );
  }

  unImpersonateUser() {
    const originalToken = this.#originalToken();

    if (originalToken === null) {
      return throwError(() => 'Original token is missing, this session is not impersonated');
    }

    this.#impersonatingAccountId.set(null);
    this.#originalToken.set(null);
    this.actionsOnLogin({ accessToken: originalToken }, true);
  }

  private updateExpiresAt() {
    // One year from now minus one day
    this.#ls.setItemParsed('expiresAt', new Date().setFullYear(new Date().getFullYear() + 1) - 86400000);
  }

  private updateToken(token: string) {
    this.#token.set(token);
  }

  private updateActiveUser(activeUser: Me) {
    this.#isSysAdmin.set((activeUser as any).isSysAdmin ?? false);
    this.#activeUser.set(activeUser);

    if (activeUser?.currentOrganizationId) {
      this.#organisationState.setCurrentOrgId(activeUser?.currentOrganizationId);
    }
  }
}
